import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/blog_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>For data notebooks, functions aren't enough</h1>
    <h3 style={{
      "marginTop": "-40px"
    }}>(and what to do about it)</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7a818b33320bcc77d0d8633e9ac64cc8/8963a/datawork-me.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAACIklEQVQoz0XS20/ScRzG8e/vy+EHHgBFbZPMptOl0kFTEjQjbYlmZQeP4SYKCggS5IQ4iI4kSZOtslqz29ZVF173v70betHF5+K5ee3Zno+QGgVN5bQKWoNErdWiVksamqy89C6wEo3i2wizFFhhdmmexWUvqyE/H09/spkMkymmSeyl6brZjrXZhNDrJBpFIIQ4RxUpUFV5nl8FAmwXisQyO8SzOdZjUbbye7wtFHgdjzL1yEX2IEvhpMz49AMabXUIKQU1NVXoVd05cm/cg3PyKW2dHeQOP7G1VySe2yWeyRPLpMnuFvA9m8F5vYNOWy0Lvhny5fesJYJc7bBVDIHtSjOL/g28oQSnv874GgmT/XDCzvE3MqUy0XSeAfcgPQPd+H1B5odGmHS7aGow4hoZILWfIXdUxO25j5AaSaWlY3iI73/+cnj6m7PNICf7JZJHX9h+d4DDcxtziw5zvZmR/l6GHDfI2JsZM2mwD/YRSUbYOS6xGo9cNKyMYjBIAvEkufIP4qXPFJNpcvvHeCMhrO1G1GoddXUmLjVaMFuq6KpXGTYKnI4eZpdfEE5GiO2mLkChCGpMVSz411l7k2J1M8F6MIwvvEHPYBcag0AqCgajFovVglqrp80suWaWPLa3MDfhYnrpOaFU4j8oNYJb/X2MTowzPDbKXc9DWrtbUU0SIS++QKvToDdoUXQCqVfQqZLLrY303rHjdPczNfeEfzJ6GD/0mD3mAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "the joker laughing after batman hits him. an arrow labelled \"me\" is pointing at batman and another labelled \"data work\" is pointed at the joker ",
            "title": "the joker laughing after batman hits him. an arrow labelled \"me\" is pointing at batman and another labelled \"data work\" is pointed at the joker ",
            "src": "/static/7a818b33320bcc77d0d8633e9ac64cc8/c1b63/datawork-me.png",
            "srcSet": ["/static/7a818b33320bcc77d0d8633e9ac64cc8/5a46d/datawork-me.png 300w", "/static/7a818b33320bcc77d0d8633e9ac64cc8/0a47e/datawork-me.png 600w", "/static/7a818b33320bcc77d0d8633e9ac64cc8/c1b63/datawork-me.png 1200w", "/static/7a818b33320bcc77d0d8633e9ac64cc8/d61c2/datawork-me.png 1800w", "/static/7a818b33320bcc77d0d8633e9ac64cc8/8963a/datawork-me.png 1918w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`The Dark Knight`}</em>{` has this great scene where Batman punches The Joker to intimate him into giving up information and he just starts laughing and says:`}</p>
    <blockquote>
      <p parentName="blockquote">{`You have nothing! Nothing to threaten me with. Nothing to do with all your strength.`}</p>
    </blockquote>
    <p>{`Batman, shocked and powerless, just stands there like an idiot.`}</p>
    <p>{`While working in a data notebook a few months back, I felt the same way. The typical power that I had as a programmer felt worthless against the data work I was doing. No matter what I did, I still found myself:`}</p>
    <ul>
      <li parentName="ul">{`typing the same code repetitively`}</li>
      <li parentName="ul">{`worrying about making mistakes while writing data wrangling and model training code`}</li>
    </ul>
    <p>{`If you're building an application or library, the solution to both of these problems is simple: write a function that does the repetitive thing and write more tests. That doesn't work for programmatic data work. Below I explain why and highlight how a solution using `}<a parentName="p" {...{
        "href": "https://docs.python.org/3/library/ast.html",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`python's ast module`}</a>{` and ipython's `}<a parentName="p" {...{
        "href": "https://ipython.readthedocs.io/en/stable/config/callbacks.html",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`event callbacks`}</a>{` and `}<a parentName="p" {...{
        "href": "https://ipython.readthedocs.io/en/stable/config/intro.html#profiles",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`profiles`}</a>{` does the job better than ordinary functions and tests.`}</p>
    <h2 {...{
      "id": "repetitive-code"
    }}><a parentName="h2" {...{
        "href": "#repetitive-code"
      }}>{`repetitive code`}</a></h2>
    <p>{`If you've been working with data and python, you could probably write code like this in your sleep:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "py"
    }}><pre parentName="div" {...{
        "className": "language-py"
      }}><code parentName="pre" {...{
          "className": "language-py"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` pandas `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` pd
df `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` pd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`read_csv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"https://.../palmer_penguins.csv"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
df`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`plot`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`scatter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'bill_length_mm'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` y`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'bill_depth_mm'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Hopefully at some point we realize we're wasting time typing the same code`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{` and we reach for a library like `}<a parentName="p" {...{
        "href": "https://pandas-profiling.ydata.ai/docs/master/index.html",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`pandas-profiling`}</a>{` or `}<a parentName="p" {...{
        "href": "https://github.com/fbdesignpro/sweetviz",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`sweetviz`}</a>{`. These are great libraries that are doing the best they can with ordinary python functions, but they aren't enough for two reasons.`}</p>
    <p>{`First, we need to iterate on a data visualization or table to see the story within the data. An initial scatter plot is great, but what if I want to put the x-axis on a log scale or color the data points by some column? Some of these "next steps" are supported in these libraries, but they can't anticipate or support every iteration I may need to make and when they fail to anticipate my next step, I'm back to writing repetitive code again.`}</p>
    <p>{`Insofar as we see functions as black boxes whose implementation should be hidden/encapsulated from users, we can't use them to solve this problem. We need the source of the code that generated the results we see so we can iterate.`}</p>
    <p>{`Second, the reports they generate aren't tailored to my particular data set, which means we're often flooded with information we don't need, making it more likely we'll miss the stuff we do need. Yes, you can remove certain aspects of these reports to cut down on noise, but even this is limited and you can't easily add to the results they generate.`}</p>
    <p>{`These libraries could introduce more customization to help address this, but as functions, they couldn't vary their output based on the code that I'm currently writing in a cell, and as we'll see, this is important for dealing with bad data/code.`}</p>
    <h2 {...{
      "id": "bad-datacode"
    }}><a parentName="h2" {...{
        "href": "#bad-datacode"
      }}>{`bad data/code`}</a></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/01c43881c1fdec17ff0d48497f2f469d/2b608/missing-values.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAABW0lEQVQoz5WSO4+CUBCF+ZUqta0dkaA22oASGmsTYox2/iuhEHn4iAiins252dkQ47ruTU7ui/numWE0fI/H44GiKOD7PgaDAUajESzL+lO9Xg/dbhe2bSOOY2hVVaEsS1wuF5zPZziOg2aziU6ng/l8jtVqheVy+VaLxQLr9RpJkkC7Xq8KlOe5cui6LnRdR7/fR5qm6kzuKVnXZ5o5nU44Ho/QmK4E8WI6naLdbmM4HGKz2WC322G73SpFUfR2PhwO0LIsA10SRvBkMkGr1VL1CYJApUFoXazV855QBWRahHHDFMbjMRqNBkzTVEDeS5CA6nP9XKVMEB0SRvBsNoNhGPA8D2EY/t8h24Xjfr+rg/1+r17i+tnZR0DpQbYOQbwQvQr+2CHTpbvfguUBzh8BWUc6ZM1eiY+J+KOe7wllL/6kzHG73ZRT1lMkP0wgDGSrMYbfS7korr8AMI5a96OqeD4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "example missing values viz",
            "title": "example missing values viz",
            "src": "/static/01c43881c1fdec17ff0d48497f2f469d/c1b63/missing-values.png",
            "srcSet": ["/static/01c43881c1fdec17ff0d48497f2f469d/5a46d/missing-values.png 300w", "/static/01c43881c1fdec17ff0d48497f2f469d/0a47e/missing-values.png 600w", "/static/01c43881c1fdec17ff0d48497f2f469d/c1b63/missing-values.png 1200w", "/static/01c43881c1fdec17ff0d48497f2f469d/2b608/missing-values.png 1540w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This was the kind of visualization I needed thrust in my face the time that I accidentally computed a mean on a column with many missing values.`}</p>
    <p>{`If I'm building a model to predict `}<inlineCode parentName="p">{`body_mass_g`}</inlineCode>{` from `}<inlineCode parentName="p">{`bill_length_mm`}</inlineCode>{` and I imputed the mean of the `}<inlineCode parentName="p">{`bill_length_mm`}</inlineCode>{` column for all missing values, I'd want to see something like this to tip me off that there's a better play`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "543px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cc0cb29d24eaded699491d6beee81829/29579/boxplot-impute-ex.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAA9hAAAPYQGoP6dpAAABzElEQVQ4y31Uia7CIBDs/3+eeYeWorYsNGqrxiMeic7LrAXbmrwmE2CBYY/ZZgDwfD4HeDweNKNtWxhjYK3FZDKBiOj8cDjoPs+N72bc3G63WK1WWK/X2Gw2OkaEEBK896jrOp0lyrLUkbbj8YgselJVlXpAOOcUQnivo8+nab9/Jt4j8W63Q3a5XJSQxr4nCVyLg5/b17xv9x7BzBD4qAj2+z0y5oGEfE0PjAkJ7lkzJIywhdp5Xwnv9/v/Hta1eic/X/DLxSdpkauHifB2uw09JMEoZGfnKOcVnO3CFtE9KQzE5PCFgYshs9SJkLla2DcZL5KAYU2/33nsCJ0xqBZORyedh+fzGU3TpJA/csU5Hys+7aEqEabfOqaiUDbUYcVLSR7uLRGGRmmY/DXvSUfBkL1X+Sjh6XRSMb8SbCCFhbf2VaDoCXPLgnQqCP1xuRjKRj3scujyHJVdak5SDrt8sVgylkyviKnKFDZzqJuuQpj9IogbtBxRj9Zj+0DYJGTriBNIPoVz79bSFoxV7dkifBduaj2GzKZu2hYNfwzeq4z6YI4JPhxtLCTXtHPNORWjOozf9XrFetOk31f89MGm0bH/sSlIyHvx+wNVsqcs7KRPIAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "example of different mean by species",
            "title": "example of different mean by species",
            "src": "/static/cc0cb29d24eaded699491d6beee81829/29579/boxplot-impute-ex.png",
            "srcSet": ["/static/cc0cb29d24eaded699491d6beee81829/5a46d/boxplot-impute-ex.png 300w", "/static/cc0cb29d24eaded699491d6beee81829/29579/boxplot-impute-ex.png 543w"],
            "sizes": "(max-width: 543px) 100vw, 543px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`(The better play is to impute the mean `}<inlineCode parentName="p">{`bill_length_mm`}</inlineCode>{` of the particular species for that row.)`}</p>
    <p>{`If I've just trained a model that predicts penguin `}<inlineCode parentName="p">{`sex`}</inlineCode>{` from `}<inlineCode parentName="p">{`bill_length_mm`}</inlineCode>{`, `}<inlineCode parentName="p">{`bill_depth_mm`}</inlineCode>{` and `}<inlineCode parentName="p">{`flipper_length_mm`}</inlineCode>{`, I don't want to forget to look at something like this table which can help me find label quality problems and generally debug my model:`}<sup parentName="p" {...{
        "id": "fnref-3"
      }}><a parentName="sup" {...{
          "href": "#fn-3",
          "className": "footnote-ref"
        }}>{`3`}</a></sup></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1113px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/75eb760dc233bd185dc081094b6f6400/25260/model-debug.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA+0lEQVQozz2RZw6FMAyDexH2FKLsvQT3P1Sevkh9PxCOGztOa9q2lWmaZN93Oc9T8XEc+s3zLMuyyPu+MgyD4nEcZV1XPaMfDLdtm1zXJQbDOI4lDEPp+16CINDaYc/zpOs6NcOE2vF5nkvTNH+OoQbnLMv0Y0KSJFIUhU4ER1Gk+Hkeue9bB6dpqry1Voc4jsSmLEsVkqqqKv1Tw4MRgknDIDjHYwIHhqPPEJkDTFiNA2pWdlfBKtwT94uYXngScsa6aHgHQxPrQnDBmFDDOyGNrMtDUcNjwlA0vu/rBt/3iXFrMpm0LgEYMUIerq5rTQRHDzxa+jBEQ4gfTTHRWZzHnWYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "doubt-labs example",
            "title": "doubt-labs example",
            "src": "/static/75eb760dc233bd185dc081094b6f6400/25260/model-debug.png",
            "srcSet": ["/static/75eb760dc233bd185dc081094b6f6400/5a46d/model-debug.png 300w", "/static/75eb760dc233bd185dc081094b6f6400/0a47e/model-debug.png 600w", "/static/75eb760dc233bd185dc081094b6f6400/25260/model-debug.png 1113w"],
            "sizes": "(max-width: 1113px) 100vw, 1113px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`In all of these cases, what I need to see most depends on the code I just finished executing, something a function knows nothing about.`}</p>
    <p>{`Some may say, "These are rookie mistakes. You should always check for missing values, better imputation methods, and low confidence predictions." But as a former software engineer, this is unsatisfying. Good software engineers don't manually check for every single bug every time they start writing code. They have automated tests that check correctness as they write new code. Data scientists need something similar.`}</p>
    <h2 {...{
      "id": "a-better-way-metaprogramming-and-hooks-with-python--ipython"
    }}><a parentName="h2" {...{
        "href": "#a-better-way-metaprogramming-and-hooks-with-python--ipython"
      }}>{`a better way: metaprogramming and hooks with python + ipython`}</a></h2>
    <p>{`Instead of having to remember to call specific functions and sift through their possibly irrelevant output as we work, we want a workflow like this:`}</p>
    <ol>
      <li parentName="ol">{`We run a Jupyter cell`}</li>
      <li parentName="ol">{`We're automatically shown relevant visualizations and tables related to the data we're working with`}</li>
      <li parentName="ol">{`We're able to access the source code that generated those results so we can iterate on them`}</li>
    </ol>
    <p>{`To do this, we can start by registering an ipython event callback that grabs the cell source code and shows the relevant visualizations based on that source:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`show_supplementary_results`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`result`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  cell_source `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` result`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`raw_cell
  df_name `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` get_df_name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`cell_source`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` df_name `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`is`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`not`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`None`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# This executes visualization and table functions`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# based on cell_source and make those functions' source`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# available`}</span>{`
    execute_funcs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`df_name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` get_user_funcs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

get_ipython`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`events`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`register`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'post_run_cell'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  show_supplementary_results
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Place this in `}<inlineCode parentName="p">{`~/.ipython/profile_default/startup/auto.py`}</inlineCode>{`, and it'll automatically run whenever ipython starts.`}</p>
    <p><inlineCode parentName="p">{`execute_funcs`}</inlineCode>{` is the key function that'll be called automatially whenever a cell contains a data frame. Let's delve into how it works. If we had a data frame called `}<inlineCode parentName="p">{`penguins_df`}</inlineCode>{` and wanted to automatically run `}<inlineCode parentName="p">{`penguins_df.describe()`}</inlineCode>{` and `}<inlineCode parentName="p">{`penguins_df.isna().sum()`}</inlineCode>{` , we'd call `}<inlineCode parentName="p">{`execute_funcs`}</inlineCode>{` like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`execute_funcs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'penguins_df'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"df.describe()"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"df.isna().sum()"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`After executing this in a cell, we'd see the output of `}<inlineCode parentName="p">{`penguins_df.describe()`}</inlineCode>{` and `}<inlineCode parentName="p">{`penguins_df.isna().sum()`}</inlineCode>{` and the following assertion would be true:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`execute_funcs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'penguins_df'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"df.describe()"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"df.isna().sum()"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`assert`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`_os0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` _os1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"penguins_df.describe()"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"penguins_df.isna().sum()"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Notice that the source code stored in `}<inlineCode parentName="p">{`_os*`}</inlineCode><sup parentName="p" {...{
        "id": "fnref-4"
      }}><a parentName="sup" {...{
          "href": "#fn-4",
          "className": "footnote-ref"
        }}>{`4`}</a></sup>{` has substituted "df" within "df.describe()" for "penguins_df," the data frame name passed into `}<inlineCode parentName="p">{`execute_funcs`}</inlineCode>{`. This lets us quickly execute the source in `}<inlineCode parentName="p">{`_os*`}</inlineCode>{` without worrying about changing variable names, and it's powered by the following `}<inlineCode parentName="p">{`ast.NodeTransformer`}</inlineCode>{` subclass:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`VarSwapper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ast`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`NodeTransformer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`__init__`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`self`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` new_name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`None`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`__init__`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    self`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`new_name `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` new_name

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`visit_Name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`self`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` node`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ast`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` node`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`id`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"df"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      node`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`id`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` self`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`new_name
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` node

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`__call__`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`self`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`**`}</span>{`kwds`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` self`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`visit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`This subclass is invoked via `}<inlineCode parentName="p">{`execute_funcs`}</inlineCode>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`execute_funcs`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`df_name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`str`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` funcs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`list`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`str`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  df `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`eval`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`df_name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` func `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` funcs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Evaluate the functions, ensuring that the`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# df name points to the data frame named`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# by the df_name function argument`}</span>{`
    display`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`eval`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`func`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'df'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` df`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Transform the source code`}</span>{`
  nodes `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ast`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`parse`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` funcs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  transformed_nodes `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`VarSwapper`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`df_name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` nodes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  sources `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ast`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`unparse`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` transformed_nodes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Assign output variables so we can access source`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` idx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` source `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`enumerate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`sources`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`globals`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string-interpolation"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`f"_os`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`idx`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` source`}</code></pre></div>
    <p>{`If you put all this code in the aforementioned ipython profile file, you can execute and retrieve the source of arbitrary code automatically as you work in your notebook.`}</p>
    <h2 {...{
      "id": "conclusion"
    }}><a parentName="h2" {...{
        "href": "#conclusion"
      }}>{`conclusion`}</a></h2>
    <p>{`Python's ast module and ipythons extension points take us beyond what ordinary functions can offer, and I think they unlock workflows that lead to better analysis and better performing ML models. With these tools, we can tackle our data work more effectively and stop feeling like Batman standing there like an idiot.`}</p>
    <p>{`If you'd like to experience a workflow like above one I've described, I've built `}<a parentName="p" {...{
        "href": "https://marketplace.visualstudio.com/items?itemName=data-chimp.data-chimp",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`a vscode plugin`}</a>{` (currently in open beta) that automatically shows contextual data visualizations, tables, messages, and docs as you work in your notebook. Customize it to help you spot unexpected features in your data, get oriented in a new data set quickly, or enforce best practices on your team:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9910798bb0a9f8c42addad78ffaa252e/e57e0/screenshot.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACcElEQVQoz3WSzWsTQRjG99pbzCFNu5tmszv7PUkz2W3S1NI2iLWlKKL2JhahpbY2+NHioT14lKTBg0WwrIqHSi2pKQhFvHjwYGFPXsTiQfBfUCjBjPvKTGhpoR5+PDPD8MzzvvMKQ0V3sjjQ/62/OLDXXxwI+vryQSbTG3j5fOB6buC6Hj/L5dyAEBJgjDmOc4I91/X2CSFlIUvc+yTnAk5nQFFUSCZlkOUUmKYN2MHgOBhMywJdN0DTdND1NuyuLMuQSqX43rJsUBTliWDjzMLY+ARcv3GzOTo+QS9dvkqHhktU1UxKsoQ6DqYIaRQhxFHVU2myxxBCVSEaPVOuVqsAAK3fB82QLTa36tDZJYLneUAI4UlEUQRJkiCRSJxGi6kkSTUhEomUV1dXuSEAcMNGYwdi8W4YHDwLhUKBl6SqKksAPT09p9Fiyg1jsVh5eXkFvv/42fr46XP45es+VGqPoUtMgOu6kE6nwTAMbsqSHk/GTA4THhlqmlaem5uHxrvd1tP1F+HGm224c28JEskU5AjhZWezWV76oTn7iP8mjEajRz1s/Q1ZyWF9uxF2dolhoZAPCSFhprc3xBiHpmmGhmmGiqKEkiQdh/dQFMWa0NHRsVCpVJhh89fBH9ZHurlVp/F4nDo4TW3boo5lUseyqG221TIMauo6tQydq6nrTQ0hNm5VIZlMLs7P34bd9x/g+ctXUH+7A4tLD6C7WwTUHgVAqgoa43CN0AnYGZ9hObUmOOOl0rmplY3Ju2vrF2cf+VcWav75qYe+VprxtZFpH43M+Kg009ZjqCPTvj0666fHZpk+wxduvdaGp679A3IVbeB4G6k3AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "screenshot of data chimp",
            "title": "screenshot of data chimp",
            "src": "/static/9910798bb0a9f8c42addad78ffaa252e/c1b63/screenshot.png",
            "srcSet": ["/static/9910798bb0a9f8c42addad78ffaa252e/5a46d/screenshot.png 300w", "/static/9910798bb0a9f8c42addad78ffaa252e/0a47e/screenshot.png 600w", "/static/9910798bb0a9f8c42addad78ffaa252e/c1b63/screenshot.png 1200w", "/static/9910798bb0a9f8c42addad78ffaa252e/d61c2/screenshot.png 1800w", "/static/9910798bb0a9f8c42addad78ffaa252e/97a96/screenshot.png 2400w", "/static/9910798bb0a9f8c42addad78ffaa252e/e57e0/screenshot.png 4064w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`Quickly banging out code like this to see a visualization is addictive. It's easy, and there's the possiblity of a big reward once you hit enter. Maybe you find a bombshell insight. Maybe you come up with an idea for a new feature to feed into your ML model. This `}<a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Reinforcement#:~:text=Ratio%20schedules%20produce%20higher%20rates,Reinforcement%20Extinction%20Effect%20(PREE).",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`variable ratio reward schedule`}</a>{` turns the data nobook into a slot machine, and it can keep us content with writing repetitive code indefinitely.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`Thanks to Marysia Winkels for the example. She dicusses it more in `}<a parentName="li" {...{
            "href": "https://www.youtube.com/watch?v=pPgic2V7oWg&t=9046s",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`this PyData Global talk`}</a>{`.`}<a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-3"
        }}>{`I generated this table using `}<a parentName="li" {...{
            "href": "https://github.com/koaning/doubtlab",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Vincent Warmerdam's doubtlab package`}</a>{`.`}<a parentName="li" {...{
            "href": "#fnref-3",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-4"
        }}>{`The "os" in `}<inlineCode parentName="li">{`_os`}</inlineCode>{` and `}<inlineCode parentName="li">{`_os1`}</inlineCode>{` stands for "output source," and these variables are meant to feel like the standard `}<inlineCode parentName="li">{`_`}</inlineCode>{` `}<inlineCode parentName="li">{`__`}</inlineCode>{` variables that store the most recent outputs as a part of `}<a parentName="li" {...{
            "href": "https://ipython.readthedocs.io/en/stable/interactive/reference.html#output-caching-system",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`ipython's caching system`}</a>{`.`}<a parentName="li" {...{
            "href": "#fnref-4",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      